import { getWidgetsAssetsEndpoint } from './getWidgetsAssetsEndpoint';
import { isStorybook } from './isStorybook';

export const loadCSS = (component: string): void => {
  if (
    !isStorybook &&
    typeof window !== 'undefined' &&
    !document.getElementById(`hawkStyles-${component}`)
  ) {
    const linkTag = document.createElement('link');
    linkTag.rel = 'stylesheet';
    linkTag.type = 'text/css';
    linkTag.id = `hawkStyles-${component}`;
    linkTag.href = `${getWidgetsAssetsEndpoint()}/css/browser/${
      process.env.VERSION
    }/${component}.min.css`;
    document.head.appendChild(linkTag);
  }
};
